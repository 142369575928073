import { pathConfig } from '@rikstv/shared-strim-paths';

import { isRunningTests } from '../../utils/isRunningTests';
import { getHostWithPort } from '../../utils/url/url.utils';
import { getEnvVariables } from '../getEnvVariables';

import { getLocalEnv, getSharedConfig } from './config.shared';
import { AppConfig } from './config.types';
import { ConfigEnvs, envs } from './config.utils';

const envMappings = {
  localhost: envs.LOCAL,
  'www.dev.strim.no': envs.DEV,
  'www.uat.strim.no': envs.UAT,
  'www.pt.strim.no': envs.PT,
  'www.strim.no': envs.PROD,
};

const getEnv = () => {
  if (isRunningTests()) {
    return envs.TEST;
  }

  const env = envMappings[window.location.hostname as keyof typeof envMappings];

  if (!env) {
    console.warn('Unable to resolve environment from host name. Defaulting to LOCAL');
    return envs.LOCAL;
  }

  return env;
};

const ENV = getEnvVariables();

export default () => {
  const env = getEnv();
  const localEnv = env === envs.LOCAL ? getLocalEnv() : undefined;
  const configEnvs = new ConfigEnvs(env, localEnv);
  const sharedConfig = getSharedConfig(configEnvs);

  const Config: AppConfig = {
    ...sharedConfig,
    basePageTitle: 'Strim | Serier, filmer og TV på ett sted',
    baseApplicationUrl: configEnvs.forEnv({
      [envs.PROD]: 'https://www.strim.no',
      [envs.PT]: 'https://www.pt.strim.no',
      [envs.TEST]: 'https://www.dev.strim.no',
      [envs.UAT]: 'https://www.uat.strim.no',
      [envs.DEV]: 'https://www.dev.strim.no',
      [envs.LOCAL]: 'https://localhost:40800',
    }),
    player: {
      ...sharedConfig.player,
      streamKick: {
        title: 'Avspillingen stoppet',
        message1: 'Avspillingen har blitt pauset fordi dere ser på flere enn to enheter samtidig.',
        message2: 'Trykk "play" for å fortsette å se.',
      },
      muxEnvironmentKey: configEnvs.forEnv({
        [envs.PROD]: 'e5ante71p0ohduecfrcdnclb9',
        [envs.PT]: '8bb14d5dh73tlh4u40sit15h4',
        [envs.TEST]: '8bb14d5dh73tlh4u40sit15h4',
        [envs.UAT]: '8bb14d5dh73tlh4u40sit15h4',
        [envs.DEV]: '8bb14d5dh73tlh4u40sit15h4',
        [envs.LOCAL]: '8bb14d5dh73tlh4u40sit15h4',
      }),
    },
    application: 'Strim',
    request: {
      appId: `Strim-Browser/${sharedConfig.appVersion}`,
    },
    iosApp: {
      appName: 'Strim',
      appStoreId: '1361563966',
      link: 'https://itunes.apple.com/no/app/strim/id1361563966?l=nb&mt=8',
    },
    androidApp: {
      appName: 'Strim',
      package: 'com.strimmobile',
      link: 'https://play.google.com/store/apps/details?id=com.strimmobile',
    },
    sentry: {
      DSN: 'https://fd93930722804706a7e6910f1d2b3d26@o575998.ingest.sentry.io/5783662',
      projectName: 'strim-web-tv',
      envName: configEnvs.forEnv({
        [envs.PROD]: 'prod',
        [envs.PT]: 'pt',
        [envs.TEST]: 'test',
        [envs.UAT]: 'uat',
        [envs.DEV]: 'dev',
        [envs.LOCAL]: 'local',
      }),
    },
    logToServer: configEnvs.forEnv({
      [envs.PROD]: { enabled: true, enableForPercentage: 50 },
      [envs.PT]: { enabled: true },
      [envs.TEST]: { enabled: false },
      [envs.UAT]: { enabled: false },
      [envs.DEV]: { enabled: false },
      [envs.LOCAL]: { enabled: false },
    }),
    cast: {
      brand: 'Strim',
      namespace: 'urn:x-cast:no.rikstv',
      receiverApplicationId: configEnvs.forEnv({
        [envs.PROD]: 'EDDBFD5C',
        [envs.PT]: 'FA630DF8',
        [envs.TEST]: 'A2B80C24',
        [envs.UAT]: 'A2B80C24',
        [envs.DEV]: 'DB662BBB',
        [envs.LOCAL]: 'FA630DF8',
      }),
    },
    webSocketUrl: configEnvs.forEnv({
      [envs.PROD]: 'https://ws.rikstv.no:443/companion',
      [envs.PT]: 'https://ws.rikstv.no:443/companion',
      [envs.TEST]: 'https://ws.uat.rikstv.no:443/companion',
      [envs.UAT]: 'https://ws.uat.rikstv.no:443/companion',
      [envs.DEV]: 'https://ws.dev.rikstv.no:443/companion',
      [envs.LOCAL]: 'https://ws.dev.rikstv.no:443/companion',
    }),
    share: {
      url: 'https://share.strim.no/render/',
    },
    auth: {
      clientId: 'strim_webapp_pkce',
      stsUrl: configEnvs.forEnv({
        [envs.PROD]: 'https://sts.strim.no',
        [envs.PT]: ENV.VITE_APP_STS_OVERRIDE || 'https://sts.strim.no',
        [envs.TEST]: ENV.VITE_APP_STS_OVERRIDE || 'https://sts.uat.strim.no',
        [envs.UAT]: ENV.VITE_APP_STS_OVERRIDE || 'https://sts.uat.strim.no',
        [envs.DEV]: ENV.VITE_APP_STS_OVERRIDE || 'https://sts.dev.strim.no',
      }),
      scope: 'urn:rikstv:onemonth openid offline_access',
      oidcCallbackUrl: configEnvs.forEnvWithDefault({}, `${getHostWithPort()}/app/callback`),
      oidcSilentRenewUrl: configEnvs.forEnvWithDefault({}, `${getHostWithPort()}/silent-renew.html`),
      oidcPostLogoutUrl: configEnvs.forEnvWithDefault({}, `${getHostWithPort()}/logout/success`),
      tokenExpirationBuffer: configEnvs.forEnvWithDefault(
        {
          [envs.PROD]: 12 * 3600,
          [envs.PT]: 12 * 3600,
        },
        60
      ),
      postLogoutRedirect: '/webapp-auth/logout/',
    },
    analytics: {
      targetId: 'G-BDGPX5F4YR',
    },
    interAppPaths: pathConfig.filter(path => path.application !== 'Strim.Play').map(path => path.pattern),
    strimAdminUrl: configEnvs.forEnv({
      [envs.PROD]: 'https://admin.strim.no',
      [envs.PT]: 'https://admin.strim.no',
      [envs.TEST]: 'https://admin.dev.strim.no',
      [envs.UAT]: 'https://admin.uat.strim.no',
      [envs.DEV]: 'https://admin.dev.strim.no',
    }),
    tv2PlayDisneyPlusActivationUrl: configEnvs.forEnv({
      [envs.PROD]: 'https://play.tv2.no/disney-aktivering',
      [envs.PT]: 'https://stage-sumo.tv2.no/disney-aktivering',
      [envs.TEST]: 'https://stage-sumo.tv2.no/disney-aktivering',
      [envs.UAT]: 'https://stage-sumo.tv2.no/disney-aktivering',
      [envs.DEV]: 'https://stage-sumo.tv2.no/disney-aktivering',
    }),
    sanityDataset: configEnvs.forEnv({
      [envs.PROD]: 'strim-prod',
      [envs.PT]: 'strim-prod',
      [envs.TEST]: 'strim-uat',
      [envs.UAT]: 'strim-uat',
      [envs.DEV]: 'strim-dev',
    }),
  };

  return { configEnvs, Config };
};
